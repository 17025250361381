site.searchBox = {
	init: function() {
		var $searchBox = $('#searchBox');
		if ($searchBox.length > 0) {
			if ($searchBox.hasClass('toggle')) {
				site.searchBox.toggle();
			}
		}
	},

	toggle: function() {
		var $body = $('body'),
			$s = $('#searchBox');

		$s.on('click touchstart', function (e) {
			e.stopPropagation();
		});

		$('#searchToggle').on('click touchstart', function (e) {
			e.stopPropagation();
			e.preventDefault();

			$body.toggleClass('search-open');

			$('body.search-open').on('click touchstart', function (e) {
				site.searchBox.close(true);
			});

			if ($body.hasClass('search-open')) {
				$s.find('#q').focus();
				$('i', '#searchToggle').removeClass('fa-search').addClass('fa-times');
			}
			else {
				site.searchBox.close(true);
			}
		});
	},

	close : function(closing) {
		closing = closing || false;
		var $body = $('body');

		if (!closing && !$body.hasClass('search-open')) return;

		$body.removeClass('search-open');
		$('i', '#searchToggle').removeClass('fa-times').addClass('fa-search');

		if (closing) {
			$body.addClass('search-closing')
			setTimeout(function() {
				$('body').removeClass('search-closing');
			},400);
		}
	}
};

site.inits.push('site.searchBox.init()');
