site.nav = {
	selector: '#mainmenu',

	init: function () {
		//site.nav.cloneMenu();
		site.nav.bind();
		//site.nav.prependSearchBox();
		//site.nav.appendLanguage();
		//site.nav.appendQuickNav();
	},

	cloneMenu: function () {
		var $target = $('#mainmenu'),
			selector = site.nav.selector.substr(1),
			$html = $('<div id="' + selector + '" role="navigation"></div>'),
			$menu = $target.find('.mmLevel1').clone().removeAttr('id');
		$menu.find('ul').removeAttr('id');
		$html.append($menu);
		$('body').append($html);
		$target.addClass('hidden-sm hidden-xs');
	},

	bind: function () {
		// Add expand link to mobile menu
		$('.children', site.nav.selector).prepend('<span class="expand"><i class="fa fa-angle-down"></span>');
		$('li[class*="selectedLevel"]', site.nav.selector).addClass('down');

		// Mobile menu toggle
		$('#mainmenuToggle').on('click touchstart', function (e) {
			e.stopPropagation();
			e.preventDefault();

			var menuOpen = $('body').hasClass('mainmenu-open');
			site.nav.mobileToggle(e, menuOpen);

			if (!menuOpen) {
				$(this).attr('aria-expanded', true);
			}
			else {
				$(this).removeAttr('aria-expanded');
			}

			$(site.nav.selector).on('click touchstart', function (e) {
				e.stopPropagation();
			});

			$('body.mainmenu-open').on('click touchstart', function (e) {
				$('body').removeClass('mainmenu-open');
				site.nav.closeMenu();
			});
		});

		// Expand in mobile menu
		$('.expand', site.nav.selector).bind('click touchstart', function (e) {
			e.preventDefault();
			$(this).closest('li').toggleClass('down');
		});
	},
	// Toggle mobile menu
	mobileToggle: function (e, menuOpen) {
		$('body').toggleClass('mainmenu-open');

		if (menuOpen) {
			site.nav.closeMenu();
		}
	},

	closeMenu: function() {
		var $body = $('body');
		$body.addClass('mainmenu-closing')
		setTimeout(function() {
			$('body').removeClass('mainmenu-closing');
		},300);

	},

	// Append language to site
	appendLanguage: function () {
		var $divisions = $('.menu', '#divisions');
		var $menu = $('ul.mmLevel1', site.nav.selector);

		var $html = $('<li class="ele-language si level1"></li>');
		$divisions.find('li').each(function () {
			var $li = $(this).clone();
			$html.append($li.find('a'));
		});

		$menu.append($html);
	},

	appendQuickNav: function () {
		var $qn = $('#qn');
		var $menu = $('ul.mmLevel1', site.nav.selector);
		var $qnContent = '';

		$qn.find('li').each(function () {
			$qnContent += '<li class="qn level1">' + $(this).clone().html() + '</li>';
		});
		$menu.append($qnContent);
	},

	prependSearchBox: function () {
		var $mainmenu = $(site.nav.selector),
			$mSearchBox = $('#searchBox').clone().removeAttr('id').attr('id', 'mSearchBox');
		$mainmenu.prepend($mSearchBox);

		$('[id]', $mSearchBox).each(function () {
			var $this = $(this),
				$id = $this.attr('id'),
				$newId = 'm' + $id.substr(0, 1).toUpperCase() + $id.substr(1),
				$label = $('label[for="' + $id + '"]', $mSearchBox);

			if ($label.length > 0) {
				$label.attr('for', $newId);
			}
			$this.attr('id', $newId);
		});
	}
};

site.inits.push('site.nav.init()');
