site.share = {
	init: function () {
		$('.share').click(function(e) {
			e.preventDefault();
			var width = ($(this).data('width') !== undefined) ? $(this).data('width') : 550;
			var height = ($(this).data('height') !== undefined) ? $(this).data('height') : 500;
			site.share.popCenter($(this).attr('href'), 'shareWindow', width, height);
		});
	},

	popCenter: function(url, title, w, h) {
		var dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left;
		var dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top;

		var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
		var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

		var left = ((width / 2) - (w / 2)) + dualScreenLeft;
		var top = ((height / 2) - (h / 2)) + dualScreenTop;
		var newWindow = window.open(url, title, 'scrollbars=0, toolbar=0, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

		// Puts focus on the newWindow
		if (window.focus) {
			newWindow.focus();
		}
	}
};

site.inits.push('site.share.init()');
