site.accordion = site.accordion || {};
site.accordion = $.extend({
	init: function () {
		var $accordion = $('section.accordion');
		$accordion.each(function () {
			var $this = $(this);
			$('>*:not(:first-child)', $this).wrapAll('<div class="wrap" />');
			$('>:first-child', $this).contents().eq(0).wrap('<a href="#">');
			$('>:first-child > a:first-child', $this).on('click', function (e) {
				e.preventDefault();
				e.stopPropagation();
				$this.toggleClass('open');

				if ($this.hasClass('open')) {
					site.accordion.checkForSubSections($this);
					site.accordion.addTimeoutClass($this, 'opening');
				}
				else {
					site.accordion.addTimeoutClass($this, 'closing');
				}
			});
		}).on('click', function(e) {
			var $this = $(this);
			if (!$this.hasClass('open')) {
				e.preventDefault();
				e.stopPropagation();
			}

			if ($this.attr('class').indexOf('open') === -1 && $(e.target).prop('tagName') !== 'A') {
				$this.find('>:first-child a').trigger('click');
			}
		});

		site.accordion.initMaxHeight();
		$(window).load(function() {
			setTimeout(function() {
				$accordion.addClass('ready');
			}, 1);
			site.accordion.hashOpen();
		});
	},

	addTimeoutClass: function (element, className, time) {
		time = (time === undefined) ? 400 : time;
		element.addClass(className);
		setTimeout(function () {
			element.removeClass(className);
		}, time);
	},

	initMaxHeight: function () {
		if ($('section.accordion .wrap').length === 0) return;
		$(window).load(function() {
			site.accordion.setHeight();
		});
		$(window).resize(function () {
			site.accordion.setHeight();
		});
	},

	setHeight: function ($elm) {
		var $accordion = $elm !== undefined ? $elm : $('section.accordion');
		var max = 0;
		$accordion.each(function() {
			var $this = $(this),
				isOpen = $this.hasClass('open');

			$this.addClass('closing');
			$('> .wrap', $this).each(function () {
				var height = $(this)[0].scrollHeight;
				$(this).css('max-height', height);
				if (height > max) {
					max = height;
				}
			});

			if (!isOpen) {
				$this.removeClass('closing');
			}
		});
		return max;
	},

	getOpenOffset: function() {
		return 30;
	},

	hashOpen: function() {
		var $accordion = $('section.accordion');
		if ($accordion.length > 0 && location.hash.length > 0 && !$('body').hasClass('admin')) {
			var $open = $accordion.filter(location.hash);
			$open.addClass('open');

			$('body,html').animate({
				scrollTop: $open.offset().top - site.accordion.getOpenOffset()
			});
		}
	},

	checkForSubSections: function($parent) {
		var $subSections = $('section.accordion', $parent);
		if ($subSections.length === 0) {
			if ($parent.closest('section.accordion').length > 0) {
				$parent = $parent.parents('section.accordion');
				$subSections = $('section.accordion', $parent);
			}
		}

		if ($subSections.length > 0) {
			var max = site.accordion.setHeight($subSections);
			var $wrap = $('>.wrap', $parent);
			var currentHeight = $wrap[0].scrollHeight;
			$parent.removeAttr('style');
			setTimeout(function () {
				$wrap.css('max-height', currentHeight + max);
			}, 400);

		}
	}
}, site.accordion);

site.inits.push('site.accordion.init()');
