/**
 * JS employees filter
 * @type {{$, g}}
 */
var empFilter = (function ($) {
	"use strict";
	var $empFilter,
		init = function () {
			$empFilter = $('.filterControls', '#employees');
			if ($empFilter.length > 0) {
				empFilter.main.init();
			}
		};
	$(document).ready(init);
	return {
		$: $,
		g: function () {
			return $empFilter.closest('.box');
		}
	};
})(jQuery);

empFilter.main = (function (parent) {
	"use strict";
	var $ = parent.$,
		$form,
		$input,
		$select,
		$link,
		$list,
		init = function () {
			$list = parent.g().find('.employee__row');
			jQuery.expr[':'].startsWith = function (a, i, m) {
				//noinspection JSConstructorReturnsPrimitive
				var t = $(a).text().replace(/^\s*/, '').substring(0, 1);
				return (t || "").toUpperCase().indexOf(m[3].toUpperCase()) >= 0;
			};
			jQuery.expr[':'].containsIC = function (a, i, m) {
				var t = $(a).text();
				return (t || "").toUpperCase().indexOf(m[3].toUpperCase()) >= 0;
			};
			initAlphaFilter();
			initSearch();
			initMaxHeight();
			initClick();
			$(window).resize(function() {
				initMaxHeight();
			});
		},

		initAlphaFilter = function() {
			var $alphaFilter = parent.g().find('.alphaFilter');
			if ($alphaFilter.length > 0) {
				$('a', $alphaFilter).each(function() {
					var char = $(this).data('char');
					if ($list.find('.employee__toolbar__name:startsWith("' + char + '")').length === 0) {
						$('span', $(this)).unwrap();
					}
				});
			}
		},

		initSearch = function () {
			$form = parent.g().find('form');
			$form.on('submit', function(e) {
				e.preventDefault();
			});
			$input = $form.find('input').keyup(function (e) {
				//if (e.which == 13) { // Enter
				var t = this;
				doSearch.call(t);
				//}
			});
			$select = $form.find('select').change(doSearch);
			$link = $form.find('a').click(doSearch);

			//TODO: use selectedHash to filter on load
			//var hash = location.hash.slice(1);
			//var selectedHash = hash.length > 0 ? hash : '';
		},

		doSearch = function (e) {
			$('.branchTitle').removeClass('hide');

			if (e) {
				e.preventDefault();
			}
			var $this = $(this),
				$res = $list,
				t = this.tagName;

			//Select
			if (t === 'SELECT') {
				$link.removeClass('selected');

				var selectValue = $select.val();
				if (selectValue) {
					$res = $res.filter('.' + selectValue);
				}
				setHash(selectValue);
			}

			//Searching
			else if (t === 'INPUT') {
				$link.removeClass('selected');

				var searchValue = $input.val();
				if (searchValue) {
					$res = $res.find(':containsIC("' + searchValue + '")').parent();
				}
			}

			//Clicking
			else {
				if ($this.hasClass('all')) {
					$link.removeClass('selected');
					$select.prop('selectedIndex', 0);
				}
				if ($this.hasClass('selected') && !$this.hasClass('all')) {
					$link.removeClass('selected');
				}
				else {
					$link.removeClass('selected');
					$this.addClass('selected');
				}

				var linkHref = $link.filter('.selected').attr('href');
				linkHref = linkHref ? linkHref.replace('#', '') : '';

				if (linkHref) {
					linkHref = linkHref.replace('#', '');

					if ($this.parent().hasClass('linksFilter')) {
						$res = $res.filter('.' + linkHref);
					}
				}
				else if ($this.data('char') && $this.data('char').length > 0) {
					$select.prop('selectedIndex', 0);
					if ($this.hasClass('selected')) {
						var selectedAlpha = $this.data('char');
						$res = $res.find('.employee__toolbar__name:startsWith("' + selectedAlpha + '")').closest('.employee__row');
						console.log($res.find('.employee__toolbar__name'));
					}
					else {
						linkHref = '';
					}
				}

				setHash(linkHref);
			}

			result($res);
		},

		result = function ($filtered) {
			if ($filtered) {
				$list.addClass('hide').removeClass('isOpen');
				$filtered.removeClass('hide');

				$('.employees__list', '.staff').each(function() {
					if ($('.employee__row:not(.hide)', $(this)).length === 0) {
						$(this).find('.branchTitle').addClass('hide');
					}
				});
			}
			else {
				$list.removeClass('hide')
			}
		},

		setHash = function(hash) {
			var scrollTop = $(window).scrollTop() ;
			location.hash = hash;
			window.scrollTo(0, scrollTop);
		},

		initMaxHeight = function() {
			var $content = $('.employee__content');

			$content.each(function() {
				var $height = $(this)[0].scrollHeight;
				$(this).css('max-height', $height + 'px');
			});
		},

		initClick = function() {
			var $row = $('.employee__row');

			$row.on('click', function() {
				if ($(this).is('.isOpen')) {
					$(this).toggleClass('isOpen');
				} else {
					$('.employee__row').removeClass('isOpen');
					$(this).addClass('isOpen');
				}
			});
		};
	return {
		init: init
	};
})(empFilter);
